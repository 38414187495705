import React from 'react';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';
import Print from 'Print';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import SearchBox from 'components/common/SearchBox';
import PrintDownloadButton from 'components/common/PrintDownloadButton';
import ItemsTable from 'components/ItemsTable';
import moment from "moment/moment";

//-----------------------------------------------------------------

class EditJob extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isLoadingCustomer: false,
            isLoadingDeliveries: true,
            job: null
        };
        this.form = new FormHelper({
            fields: {
                reference: {
                    label: 'Job Number',
                    placeholder: 'Leave blank to set automatically'
                },
                status: {
                    type: 'single-select',
                    label: 'Status',
                    getOptions: () => [
                        { value: 'Open', text: 'Open' },
                        { value: 'Closed', text: 'Closed' }
                    ]
                },
                poNumber: {
                    label: 'Purchase Order Number',
                    type: 'text'
                },
                deliveryDate: {
                    label: 'Expected Delivery Date',
                    type: 'date'
                },
                notes: {
                    label: 'Notes for Workshop Staff',
                    type: 'expanding-text'
                },
                invoiceNumber: {
                    label: 'Invoice Number',
                    type: 'text',
                    isReadOnly: () => !!this.state.job?.dateSentToQuickbooks
                },
                invoiceDate: {
                    label: 'Invoice Date',
                    type: 'date',
                    isReadOnly: () => !!this.state.job?.dateSentToQuickbooks
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.id != oldProps.id) {
            this.load();
        }
    }

    async load() {
        return new Promise(async (resolve, reject) => {
            const jobID = parseInt(this.props.id) || 0;
            let job;

            if (jobID) {
                this.setState({ isLoading: true });
                job = await API.call(`job/get/${this.props.id}`);
            } else {
                job = {
                    status: this.props.status,
                    items: []
                };
            }

            const vatRates = await API.call('vat-rate/list');

            this.setState({
                isLoading: false,
                isLoadingCustomer: !!job.customerID,
                job,
                vatRates
            }, () => {
                this.loadCustomer();
                if (jobID) {
                    this.loadDeliveries();
                }
                resolve();
            });
        });
    }

    getFieldValue(fieldName) {
        let entity = 'job';
        const fieldNameParts = fieldName.split('.');
        if (fieldNameParts.length > 1) {
            entity = fieldNameParts[0];
            fieldName = fieldNameParts[1];
        }
        switch (entity) {
            case 'job':
                return this.state.job[fieldName];
        }
    }

    async save() {
        return new Promise(async (resolve) => {
            if (this.state.isLoading) {
                return;
            }
            const { job } = this.state;
            this.setState({ isLoading: true });
            const id = await API.call('job/save', job);

            if (id && !parseInt(this.props.id)) {
                this.props.history.replace('/job/' + id);
            } else {
                await this.load();
            }
            resolve(id);
        });
    }

    async saveAndRender(type, isInline) {
        const jobID = await this.save();
        this.setState({ isLoading: true });
        await Print.url(`/api/render/${type}/pdf`, { jobID, isInline });
        this.setState({
            isLoading: false
        });
    }

    async addDelivery() {
        const jobID = await this.save();
        this.props.history.push(`/job-delivery/0/${jobID}`);
    }

    updateFields(fields) {
        const job = { ...this.state.job };
        let loadCustomer = false;
        for (let fieldName in fields) {
            const fieldNameParts = fieldName.split('.');
            let value = fields[fieldName];
            let entity = 'job';
            if (fieldNameParts.length > 1) {
                entity = fieldNameParts[0];
                fieldName = fieldNameParts[1];
            }
            if (fieldName === 'deliveryDate' || fieldName === 'date' || fieldName == 'invoiceDate') {
                value = moment(value).locale('en-gb').format('YYYY-MM-DD');
            }

            switch (entity) {
                case 'job':
                    job[fieldName] = value;

                    switch (fieldName) {
                        case 'customerID':
                            loadCustomer = true;
                            break;
                        case 'invoiceNumber':
                            if (!job.invoiceDate) {
                                job.invoiceDate = moment().locale('en-gb').format('YYYY-MM-DD');
                            }
                            break;
                    }

                    break;
            }
        }
        this.setState({
            job
        }, () => {
            if (loadCustomer) {
                this.loadCustomer();
            }
        });
    }

    async loadCustomer() {
        const { customerID } = this.state.job;
        if (customerID) {
            this.setState({ isLoadingCustomer: true });
            const customer = await API.call('customer/get/' + customerID);
            this.updateFields({ customer });
            this.setState({ isLoadingCustomer: false });
        } else {
            this.updateFields({ customer: null });
        }
    }

    async loadDeliveries() {
        this.setState({ isLoadingDeliveries: true });
        const jobDeliveries = await API.call('job-delivery/list', { jobID: this.state.job.id });
        this.setState({
            isLoadingDeliveries: false,
            jobDeliveries
        });
    }

    updateItem(index, values) {
        const items = [...this.state.job.items];
        const item = (index < items.length ?
            { ...items[index] } :
            { vatRateID: this.state.vatRates?.find(vr => vr.isDefault)?.id }
        );
        for (let fieldName in values) {
            const value = values[fieldName];
            item[fieldName] = value;
        }
        item.total = (item.quantity || 0) * (item.unitPrice || 0);
        items[index] = item;
        this.updateFields({ items });
    }

    openDelivery(id) {
        this.props.history.push(`/job-delivery/${id}`);
    }

    async confirmDelete(index) {
        const items = [...this.state.job.items];
        const item = items[index];

        let msg = 'Are you sure you want to delete this item?';
        if (item.quantityDispatched > 0) {
            msg += ' (NOTE: this item has been dispatched already!)';
        }

        const confirm = window.confirm(msg);
        if (confirm) {
            items.splice(index, 1);
            this.updateFields({ items });
        }
    }

    async sendToQuickBooks() {
        await this.save();
        this.setState({ isSendingToQuickbooks: true });
        await API.call('job/send-to-quickbooks/' + this.state.job.id);
        this.setState({ isSendingToQuickbooks: false });
        this.load();
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            job,
            vatRates,
            isSendingToQuickbooks
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.save() }}>

                <section className="control-panel sticky">

                    <h1>{this.renderTitle()}</h1>

                    <div className="button-panel ms-auto">

                        <button type="submit" className="btn btn-primary">
                            Save
                        </button>

                        <button type="button" className="btn btn-primary" onClick={() => this.addDelivery()}>
                            Add Delivery
                        </button>

                        <PrintDownloadButton
                            text="Sales Order"
                            onPrint={() => this.saveAndRender('SalesOrder', true)}
                            onDownload={() => this.saveAndRender('SalesOrder', false)}
                        />

                        <PrintDownloadButton
                            text="Job Sheet"
                            onPrint={() => this.saveAndRender('JobSheet', true)}
                            onDownload={() => this.saveAndRender('JobSheet', false)}
                        />

                        {!!job.id &&
                            <div className="dropdown">
                                <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {isSendingToQuickbooks ? <><Loader isInline />{' '}</> : <>QuickBooks</>}
                                </button>
                                {!isSendingToQuickbooks &&
                                    <ul className="dropdown-menu">
                                        {!!job.dateSentToQuickbooks &&
                                            <li>
                                                <a className="dropdown-item" href={`https://app.qbo.intuit.com/app/invoice?txnId=${job.qbInvoiceID}`} target="_blank">
                                                    View (Sent on {moment(job.dateSentToQuickbooks).format('DD/MM/YYYY')})
                                                </a>
                                            </li>
                                        }
                                        <li>
                                            <a className="dropdown-item" href="#" onClick={e => { e.preventDefault(); this.sendToQuickBooks() }}>
                                                Send to QuickBooks
                                            </a>
                                        </li>
                                    </ul>
                                }
                            </div>
                        }

                    </div>

                </section>

                <section>

                    {this.renderBasicDetails()}

                    {this.renderInvoicingDetails()}

                    <h2 className="mt-5">Items</h2>

                    <ItemsTable
                        mode="Job"
                        items={job.items}
                        vatRates={vatRates}
                        showPrice
                        onChange={(index, values) => this.updateItem(index, values)}
                        onDelete={(item, index) => this.confirmDelete(index)}
                    />

                    {!!job.id && <>
                        
                        <h2 className="mt-5">Deliveries</h2>

                        {this.renderDeliveries()}

                    </>}

                </section>

            </form>

        </>);
    }

    renderBasicDetails() {
        const {
            job
        } = this.state;

        return (
            <fieldset>

                <h2>Basic Details</h2>

                <div className="row">

                    <div className="col-md-6">

                        <div className="form-group">
                            <label className="form-label">Customer</label>
                            {this.renderCustomer()}
                        </div>

                    </div>

                    <div className="col-md-3">

                        {this.form.render('reference')}

                    </div>

                    <div className="col-md-3">

                        {this.form.render('status')}

                    </div>

                </div>

                <div className="row">

                    <div className="col-md-6">

                        {this.form.render('poNumber')}

                    </div>

                    <div className="col-md-6">

                        {this.form.render('deliveryDate')}

                    </div>

                </div>

                {this.form.render('notes')}

            </fieldset>

        );
    }

    renderInvoicingDetails() {
        return (
            <fieldset className="mt-5">

                <h2>Invoicing</h2>

                <div className="row">

                    <div className="col-md-6">

                        <div className="form-group">
                            
                            {this.form.render('invoiceNumber')}

                        </div>

                    </div>

                    <div className="col-md-3">

                        {this.form.render('invoiceDate')}

                    </div>

                </div>

            </fieldset>
        );
    }

    renderTitle() {
        const { job } = this.state;
        let title = '';
        if (!job.id) {
            title += 'New Job ';
        }
        if (job.reference) {
            title += `${job.reference} `;
        }
        if (job.customer) {
            title += 'for ' + job.customer.name;
        }
        return title;
    }

    renderCustomer() {
        const {
            isLoadingCustomer,
            job
        } = this.state;
        if (job.customer) {
            return (
                <p className="form-control-plaintext">
                    {job.customer.name} ({job.customer.reference})
                    <button className="btn btn-sm btn-primary fa fa-search ms-2" title="View customer" onClick={() => this.props.history.push(`/customer/${job.customerID}`)} />
                    <button className="btn btn-sm btn-danger fa fa-times ms-2" title="Select a different customer" onClick={() => this.updateFields({ customerID: null })} />
                </p>
            );
        }
        if (isLoadingCustomer) {
            return (
                <p className="form-control-plaintext">Loading...</p>
            );
        }
        return (
            <SearchBox
                autoFocus={!job.customerID}
                className="form-control"
                placeholder="Search..."
                minLength={2}
                search={async (query, setResults, dataObj) => {
                    dataObj.nonce = `${Math.random()}`;
                    const response = await API.call('search', { query, nonce: dataObj.nonce, types: [ 'Customer' ] });
                    if (response.nonce == dataObj.nonce) {
                        setResults(response.results);
                    }
                }}
                onClickResult={result => this.updateFields({ customerID: result.id })}
            />
        );
    }

    renderDeliveries() {
        const {
            isLoadingDeliveries,
            jobDeliveries
        } = this.state;

        if (isLoadingDeliveries) {
            return (<Loader />);
        }

        return (<>

            <SuperTable
                className="job-deliveries-table table table-bordered mb-0"
                rows={jobDeliveries}
                keyAccessor={jd => jd.id}
                cols={{
                    reference: {
                        label: 'Del. Note Number',
                        className: 'ref-col'
                    },
                    date: {
                        label: 'Date',
                        type: 'date',
                        className: 'date-col'
                    }
                }}
                onClick={(jobDelivery, e) => this.openDelivery(jobDelivery.id)}
                emptyText="No deliveries have been added yet"
            />

        </>);
    }
}

export default EditJob;