import React from 'react';
import { Link } from 'react-router-dom';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import PageList from 'components/common/PageList';

//-----------------------------------------------------------------

class ListVATRates extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingPages: true,
            vatRates: []
        };
    }
    
    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({ isLoading: true });
        const vatRates = (await API.call('vat-rate/list')) || [];
        this.setState({
            isLoading: false,
            vatRates
        });
    }

    open(id) {
        this.props.history.push(`/vat-rate/${id}`);
    }

    //----------------------------------------------------------------------

    render() {
        return (<>

            <section className="control-panel">

                <h1>VAT Rates</h1>
                
                <Link className="ms-auto btn btn-primary" to="/vat-rate">
                    New VAT Rate
                </Link>

            </section>

            <section>

                {this.renderTable()}

            </section>

        </>);
    }

    renderTable() {
        const {
            isLoading,
            vatRates
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <SuperTable
                className="vatRates-table table table-bordered mb-0"
                rows={vatRates}
                keyAccessor={vatRate => vatRate.id}
                cols={{
                    name: {
                        label: 'Name',
                        className: 'name-col'
                    },
                    rate: {
                        label: 'Rate'
                    }
                }}
                onClick={(vatRate, e) => this.open(vatRate.id)}
                emptyText={'No VAT rates to show'}
            />
        );
    }
}

export default ListVATRates;