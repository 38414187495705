import React from 'react';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditVATRate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            vatRate: null,
            vatRates: null,
            qbTaxCodes: []
        };

        this.form = new FormHelper({
            fields: {
                name: {
                    label: 'Name'
                },
                rate: {
                    label: 'Rate (0.0 - 1.0)',
                    type: 'number'
                },
                qbid: {
                    label: 'Quickbooks Tax Code',
                    type: 'single-select',
                    blankText: '(Please Select)',
                    getOptions: () => this.state.qbTaxCodes.map(qbtc => ({
                        value: qbtc.id,
                        text: qbtc.name
                    }))
                }
            },
            getValue: (fieldName) => this.state.vatRate[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.id != oldProps.id) {
            this.load();
        }
    }

    async load() {
        let vatRate;

        if (this.props.id) {
            this.setState({ isLoading: true });
            vatRate = await API.call(`vat-rate/get/${this.props.id}`);
        } else {
            vatRate = {
                address: {}
            };
        }

        const qbTaxCodes = await API.call('quickbooks/list-tax-codes');

        this.setState({
            isLoading: false,
            vatRate,
            qbTaxCodes
        });
    }

    async save() {
        if (this.state.isLoading) {
            return;
        }
        const { vatRate } = this.state;
        this.setState({ isLoading: true });
        const id = await API.call('vat-rate/save', vatRate);
        this.props.history.replace('/vat-rates');
    }   

    updateFields(fields) {
        const vatRate = { ...this.state.vatRate };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            vatRate[fieldName] = value;
        }
        this.setState({ vatRate });
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            vatRate
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <form onSubmit={e => { e.preventDefault(); this.save() }}>

                <section className="control-panel sticky">

                    <h1>{vatRate.id ? vatRate.name : 'New VAT Rate'}</h1>

                    <button type="submit" className="ms-auto btn btn-primary">
                        Save
                    </button>

                </section>

                <section>

                    <fieldset>

                        <h2>Basic Details</h2>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('name')}

                            </div>

                            <div className="col-md-6">

                                {this.form.render('rate')}

                            </div>

                        </div>

                        <div className="row">

                            <div className="col-md-6">

                                {this.form.render('qbid')}

                            </div>

                            <div className="col-md-6">


                            </div>

                        </div>

                    </fieldset>

                </section>

            </form>

        </>);
    }

}

export default EditVATRate;