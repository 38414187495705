// Libs
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb';

// Helpers
import TextHelpers from 'helpers/TextHelpers';

//-------------------------------------------------------------------------------------------------------------------

class SuperTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    formatValue(value, type, col) {
        switch ((type || '').toLowerCase()) {
            case 'currency':
                return TextHelpers.formatCurrency(value);
            case 'date':
                if (value) {
                    return moment(value).format('DD/MM/YYYY');
                }
                break;
            case 'datetime':
                if (value) {
                    return moment(value).format('DD/MM/YYYY HH:mm');
                }
                break;
            case 'percentage':
                if (value) {
                    return Math.round(Number(value) * 10000) / 100 + '%';
                } else {
                    return (col && col.blankIfZero ? '' : '0%');
                }
            case 'duration':
                if (value) {
                    return TextHelpers.formatDuration(Number(value));
                }
                break;
            default:
                return value;
        }
        return '';
    }

    render() {
        let {
            className,
            rows,
            cols,
            keyAccessor,
            sorter,
            onClick,
            rowPropsAccessor,
            cellPropsAccessor,
            emptyText,
            sortBy,
            sortDesc
        } = this.props;

        if (!rows) {
            rows = [];
            console.warn('No rows specified for <SuperTable>');
        }
        if (!cols) {
            cols = {};
            console.warn('No cols specified for <SuperTable>');
        }

        if (sorter) {
            rows.sort(sorter);
        } else if (sortBy) {
            let getDiff;
            switch (cols[sortBy].type) {
                case 'date':
                    getDiff = (a, b) => {
                        a = a[sortBy];
                        b = b[sortBy];
                        const diff = moment.duration(moment(b).diff(moment(a))).asMinutes();
                        return Math.sign(diff);
                    }
                    break;
                default:
                    getDiff = (a, b) => {
                        a = a[sortBy];
                        b = b[sortBy];
                        if (a > b) return 1;
                        else if (a < b) return -1;
                        else return 0;
                    };
                    break;
            }
            rows.sort((a, b) => getDiff(a, b) * (sortDesc ? -1 : 1));
        }

        // Convert cols to an array
        const colArray = [];
        let index = 0;
        for (var name in cols) {
            if (cols.hasOwnProperty(name)) {
                const col = cols[name];
                if (col.hide) continue;
                colArray.push({
                    name: name,
                    sortOrder: col.sortOrder || index,
                    col: col,
                    index: index++
                });
            }
        }
        colArray.sort((a, b) => a.sortOrder - b.sortOrder);

        if (onClick) {
            className = (className || '') + ' clickable';
        }

        if (rows.length == 0) {
            return (
                <div className="empty-text">
                    {emptyText}
                </div>
            );
        }

        return (<>
            <div className="table-container">
                <table className={'super-table ' + (className || '')}>
                    <thead>
                        <tr>
                            {colArray.map(colInfo =>
                                <React.Fragment key={colInfo.name}>
                                    {(colInfo.col.renderHeaderCell || this.props.renderHeaderCell)(colInfo, this.props)}
                                </React.Fragment>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) =>
                            <tr key={keyAccessor ? keyAccessor(row, index) : index} onClick={e => onClick ? onClick(row, e, index) : null} {...(rowPropsAccessor ? rowPropsAccessor(row, index) : null)}>
                                {colArray.map(colInfo => {
                                    const shouldRenderCell = this.props.shouldRenderCell ? this.props.shouldRenderCell(colInfo, row, index) : true;
                                    if (!shouldRenderCell) {
                                        return null;
                                    }
                                    const formattedValue = this.formatValue(row[colInfo.name], colInfo.col.type, colInfo.col);
                                    return (
                                        <React.Fragment key={colInfo.name}>
                                            {(colInfo.col.renderCell || ((colInfo, row) =>
                                                <td className={colInfo.col.className || ''} { ...(cellPropsAccessor ? cellPropsAccessor(colInfo, row, index) : null)}>
                                                    {colInfo.col.getValue ? colInfo.col.getValue(colInfo, row, formattedValue, index) : formattedValue}
                                                </td>
                                            ))(colInfo, row)}
                                        </React.Fragment>
                                    );
                                })}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>);
    }
}

SuperTable.defaultProps = {
    renderHeaderCell: (colInfo, props) => {
        const classNames = [ colInfo.col.className || '' ];
        if (colInfo.col.sortable) {
            classNames.push('sortable');
            if (colInfo.name == props.sortBy) {
                classNames.push('sort-by');
                if (props.sortDesc) {
                    classNames.push('desc');
                }
            }
        }
        return (
            <th className={classNames.join(' ')} onClick={e => {
                if (colInfo.col.sortable) {
                    const sortDesc = (colInfo.name == props.sortBy ? !props.sortDesc : false);
                    props.onChangeSort(colInfo.name, sortDesc);
                }
            }}>
                {colInfo.col.label}

                {colInfo.name == props.sortBy && (
                    props.sortDesc ?
                        <span className="fa fa-sort-down"></span> :
                        <span className="fa fa-sort-up"></span>
                )}
            </th>
        );
    }
};

export default SuperTable;