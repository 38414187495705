import React from 'react';
import { Link } from 'react-router-dom';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import PageList from 'components/common/PageList';

//-----------------------------------------------------------------

class ListProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingPages: true,
            products: []
        };
    }
    
    componentDidMount() {
        this.load();
    }

    async load() {
        this.setState({ isLoading: true });
        const products = (await API.call('product/list')) || [];
        this.setState({
            isLoading: false,
            products
        });
    }

    open(id) {
        this.props.history.push(`/product/${id}`);
    }

    //----------------------------------------------------------------------

    render() {
        return (<>

            <section className="control-panel">
                
                <Link className="ms-auto btn btn-primary" to="/product">
                    New Product
                </Link>

            </section>

            <section>

                {this.renderTable()}

            </section>

        </>);
    }

    renderTable() {
        const {
            isLoading,
            products
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <SuperTable
                className="products-table table table-bordered mb-0"
                rows={products}
                keyAccessor={product => product.id}
                cols={{
                    code: {
                        label: 'Code',
                        className: 'code-col'
                    },
                    name: {
                        label: 'Name',
                        className: 'name-col'
                    },
                    price: {
                        label: 'Price',
                        getValue: (colInfo, row) => row.unitPrice ?
                            TextHelpers.formatCurrency(row.unitPrice) + ' ' + row.unitType
                            : null
                    },
                    vatRate: {
                        label: 'VAT',
                        getValue: (colInfo, row) => row.defaultVATRate?.name
                    }
                }}
                onClick={(product, e) => this.open(product.id)}
                emptyText={'No products to show'}
            />
        );
    }
}

export default ListProducts;